'use strict';

window.utag_data = {};
var urlsUtagJson = require('../../../../config/urlsUtag.json');
const cookieUtils = require('../../../../../../int_ccf_omnichannel/cartridge/client/default/js/utils/utilsCookies');

module.exports = {
    tealiumTags: function () {
        var trackingConsent = $('.tracking-consent.tracking-banner').data('tracking-consent');
        var cookiePropias = cookieUtils.getCookie('c_p');
        var cookieTerceros = cookieUtils.getCookie('c_t');
        var cookieConsent = cookieUtils.getCookie('c_dnt');
        if (cookiePropias === 'true') {
            $('input#analisis_propias').prop('checked', true);
        }
        if (cookieTerceros === 'true') {
            $('input#analisis_terceros').prop('checked', true);
        }
        if (trackingConsent || cookieConsent === '0') {
            // es / ca / enIt is the language selected on the web  by the user to browse the site.
            window.utag_data.page_language = $('#idLocale').val() || '';

            // EMP o PART
            window.utag_data.client_category = window.CQuotient.siteId.indexOf('PART') > -1 ? 'PART' : 'EMP';

            // This is the geographic area selected by the user to browse the web site
            window.utag_data.page_geographic_area = $('#geoMessage').val() || '';

            // Environment
            window.utag_data.page_environment = window.CQuotient.instanceType;

            // Client ID or cookie sid // Encrypted unique identifier for each customer.
            window.utag_data.client_id = window.CQuotient.getCQUserId() ? window.CQuotient.getCQUserId() : cookieUtils.getCookie('sid');

            // <no logado|logado> | Esta variable es transversal y se debe enviar durante toda la navegación del usuario
            window.utag_data.client_login_status = window.CQuotient.getCQUserId() ? 'logado' : 'no logado';

            // customer type: wivai, caixabank, guest.
            window.utag_data.client_type = window.CQuotient.getCQUserId() ? 'wivai' : 'guest';

            var urlUtag;
            if (window.CQuotient.instanceType === urlsUtagJson.environment.prod) {
                urlUtag = urlsUtagJson.urls.urlProd;
            } else if (window.CQuotient.instanceType === urlsUtagJson.environment.stg) {
                urlUtag = urlsUtagJson.urls.urlStg;
            } else {
                urlUtag = urlsUtagJson.urls.urlDev;
            }
            // Loading script asynchronously
            // eslint-disable-next-line wrap-iife
            (function (a, b, c, d) {
                // eslint-disable-next-line no-param-reassign
                a = urlUtag;
                // eslint-disable-next-line no-param-reassign
                b = document;
                // eslint-disable-next-line no-param-reassign
                c = 'script';
                // eslint-disable-next-line no-param-reassign
                d = b.createElement(c);
                // eslint-disable-next-line no-param-reassign
                d.src = a;
                // eslint-disable-next-line no-param-reassign
                d.type = 'text/java' + c;
                // eslint-disable-next-line no-param-reassign
                d.async = true;
                // eslint-disable-next-line no-param-reassign
                a = b.getElementsByTagName(c)[0];
                a.parentNode.insertBefore(d, a);
            })();
        }
    }
};
