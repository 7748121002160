'use strict';

/**
 * This file contians just client newsletter logic wivai site
 */

const cookieUtils = require('../../../../../../int_ccf_omnichannel/cartridge/client/default/js/utils/utilsCookies');

const COOKIE_NAMES = {
    NEWSLETTER_MAX_SESSIONS_ALLOWED: 'n_msa',
    NEWSLTTER_ALREADY_DISPLAYED: 'n_ad'
};

const NEWSLETTER_MAX_SESSIONS_ALLOWED_COOKIE_EXP_TIME = 'n_msa_exp_time';

/**
 * Get the days to expire max sessions allowed
 * @returns {string|null} days or null
 */
async function getMaxSessionsAllowedCookieExpirationDays() {
    const modal = $('#newsletter-modal');
    let days = null;

    if (modal.attr('data-url')) {
        const endpoint = new URL($('#newsletter-modal').attr('data-url'));
        const res = await fetch(endpoint.toString());
        const json = await res.json();
        days = json.days;
    }

    return days;
}

/**
 * Store exp date into local storage
 */
async function storeMaxSessionsAllowedCookieExpirationDate() {
    const daysToExpire = await getMaxSessionsAllowedCookieExpirationDays();

    if (daysToExpire && daysToExpire > -1) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + daysToExpire);
        const expirationDateToUTCString = expirationDate.toUTCString();
        localStorage.setItem(NEWSLETTER_MAX_SESSIONS_ALLOWED_COOKIE_EXP_TIME, expirationDateToUTCString);
    }
}

/**
* Create a cookie to indicate whether the newsletter pop-up has already been displayed.
* @returns {void}
*/
function setNewsletterAlreadyDisplayedCookie() {
    const cookieName = COOKIE_NAMES.NEWSLTTER_ALREADY_DISPLAYED;
    const cookieValue = 'true';
    const expires = `expires=${-1}`; // Session cookie

    document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
}

/**
 * Update the times that the user has viewed the newsletter modal
 * @param {Array} cookiesArray - Array with cookies
 */
function decrementMaxSessionAllowedCookieVal(cookiesArray) {
    const { NEWSLETTER_MAX_SESSIONS_ALLOWED } = COOKIE_NAMES;
    const cookieValue = cookieUtils.getCookieValue(cookiesArray, NEWSLETTER_MAX_SESSIONS_ALLOWED);
    const expTime = localStorage.getItem(NEWSLETTER_MAX_SESSIONS_ALLOWED_COOKIE_EXP_TIME);

    document.cookie = `${NEWSLETTER_MAX_SESSIONS_ALLOWED}=${cookieValue - 1};expires=${expTime};path=/`;
}

$('#newsletter-modal .js-newsletter-modal-link').on('click', function () {
    if ($(this).attr('href') !== '') {
        window.location.href = $(this).attr('href');
    }
});

$('#newsletter-modal .modal-header > .js-newsletter-modal-close-btn').on('click', function () {
    if ($('#newsletter-modal').hasClass('go-to-subscription')) {
        $('#newsletter-modal').removeClass('go-to-subscription');
    }
});

/**
 * Recalc the padding top of the newsletter unsubscribe message,
 * on mobile devices
 * @return {void}
 */
function resizeUnsubscribeMsgOnMbl() {
    const headerBannerHeight = $('.header-banner').outerHeight(true);
    const unsubscribeMsgPaddingTop = parseFloat($('.newsletter-unsubscribe-wrapper .content-wrapper').css('padding-top'));
    const newPaddingTop = headerBannerHeight + unsubscribeMsgPaddingTop;

    $('.newsletter-unsubscribe-wrapper .content-wrapper').css('padding-top', newPaddingTop);
}

$(document).ready(async function () {
    const cookiesArray = document.cookie.split('; ');
    const {
        NEWSLETTER_MAX_SESSIONS_ALLOWED,
        NEWSLTTER_ALREADY_DISPLAYED
    } = COOKIE_NAMES;

    const isNewsletterMaxSessionsAllowedCookieSet = cookieUtils.isCookieAlreadySet(cookiesArray, NEWSLETTER_MAX_SESSIONS_ALLOWED);
    const isNewsletterModalAlreadyDisplayedCookieSet = cookieUtils.isCookieAlreadySet(cookiesArray, NEWSLTTER_ALREADY_DISPLAYED);
    const newsletterMaxSessionsAllowedExpTime = localStorage.getItem(NEWSLETTER_MAX_SESSIONS_ALLOWED_COOKIE_EXP_TIME);

    // We need to retrieve the exp time of the n_msa cookie cuz no way to get exp time from document.cookie
    if (!(isNewsletterMaxSessionsAllowedCookieSet && newsletterMaxSessionsAllowedExpTime)) {
        await storeMaxSessionsAllowedCookieExpirationDate();
    }

    if (isNewsletterMaxSessionsAllowedCookieSet && !isNewsletterModalAlreadyDisplayedCookieSet) {
        const maxSessionsAllowedCookieVal = cookieUtils.getCookieValue(cookiesArray, NEWSLETTER_MAX_SESSIONS_ALLOWED);
        const isHomePage = $('.home-page[data-site="wivai"]').length > 0;

        if (maxSessionsAllowedCookieVal > 0 && isHomePage) {
            $('#newsletter-modal').addClass(['opened', 'go-to-subscription']);
            setNewsletterAlreadyDisplayedCookie();
            decrementMaxSessionAllowedCookieVal(cookiesArray);
        }
    }

    if ($('.header-banner').is(':visible') && window.innerWidth <= 599) {
        setTimeout(() => {
            resizeUnsubscribeMsgOnMbl();
        }, 300);
    }
});
