'use strict';

var focusHelper = require('../components/focus');
const cookieUtils = require('../../../../../../int_ccf_omnichannel/cartridge/client/default/js/utils/utilsCookies');

const MILISECONDS = 1000;
const SECONDS = 60;
const MINUTES = 60;
const HOURS = 24;
const DAYS = 365;

/**
 * Sets the cookie expiration time to one year
 * @returns {Date} cookie expiration date
 */
function setExpirationTime() {
    return new Date(new Date().getTime() + MILISECONDS * SECONDS * MINUTES * HOURS * DAYS).toGMTString();
}

/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */
function showConsentModal() {
    var cookiesAnalysisOwn = cookieUtils.getCookie('c_p');
    var cookiesAnalysisThirdParties = cookieUtils.getCookie('c_t');
    var CookiesPersonalizationThirdParties = cookieUtils.getCookie('c_tp');
    var CookiesAdvertisingThirdParties = cookieUtils.getCookie('c_tpc');
    if (cookiesAnalysisOwn === 'true' || cookiesAnalysisThirdParties === 'true'
        || CookiesPersonalizationThirdParties === 'true' || CookiesAdvertisingThirdParties === 'true') {
        return;
    }
    if (!$('.tracking-consent.tracking-banner').data('caonline')) {
        return;
    }
    var urlContent = $('.tracking-consent.tracking-banner').data('url');

    var htmlString = '<!-- Banner Cookies -->'
    + '<div class="banner-cookies" id="consent-tracking">'
    + '<div class="layout-cookies">'
    + '</div>'
    + '</div>';
    $('body').append(htmlString);

    $.ajax({
        url: urlContent,
        type: 'get',
        dataType: 'html',
        success: function (response) {
            $('.layout-cookies').html(response);
        },
        error: function () {
            $('#consent-tracking').remove();
        }
    });

    $('body').on('click', 'button.affirm', function (e) {
        e.preventDefault();
        var url = $('span.tracking-consent').data('accept');
        var params = {
            consent: true,
            ownCookies: true,
            thirdPartyCookies: true
        };
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            data: params,
            success: function () {
                $('#consent-tracking').remove();
                document.cookie = 'c_p=true;expires=' + setExpirationTime() + ';path=/';
                document.cookie = 'c_t=true;expires=' + setExpirationTime() + ';path=/';
                document.cookie = 'c_tp=true;expires=' + setExpirationTime() + ';path=/';
                document.cookie = 'c_tpc=true;expires=' + setExpirationTime() + ';path=/';
            },
            error: function () {
                $('#consent-tracking').remove();
            }
        });
    });

    $('body').on('click', 'button.decline', function (e) {
        e.preventDefault();
        var url = $('span.tracking-consent').data('reject');
        var params = {
            consent: false,
            ownCookies: false,
            thirdPartyCookies: false
        };
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            data: params,
            success: function () {
                $('#consent-tracking').remove();
                document.cookie = 'c_p=false;expires=' + setExpirationTime() + ';path=/';
                document.cookie = 'c_t=false;expires=' + setExpirationTime() + ';path=/';
                document.cookie = 'c_tp=false;expires=' + setExpirationTime() + ';path=/';
                document.cookie = 'c_tpc=false;expires=' + setExpirationTime() + ';path=/';
            },
            error: function () {
                $('#consent-tracking').remove();
            }
        });
    });
}

module.exports = function () {
    if ($('.consented').length === 0 && $('.tracking-consent').hasClass('api-true')
        && window.location.href.indexOf('cookie-policy') < 0) {
        showConsentModal();
    }

    if ($('.tracking-consent').hasClass('api-true')) {
        $('.tracking-consent').click(function () {
            showConsentModal();
        });
    }

    $('body').on('shown.bs.modal', '#consent-tracking', function () {
        $('#consent-tracking').siblings().attr('aria-hidden', 'true');
        $('#consent-tracking .close').focus();
    });

    $('body').on('hidden.bs.modal', '#consent-tracking', function () {
        $('#consent-tracking').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#consent-tracking', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#consent-tracking',
            firstElementSelector: '.affirm',
            lastElementSelector: '.more-information',
            nextToLastElementSelector: '.affirm'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $(window).on('load', function () {
        var cookiesAnalysisOwn = cookieUtils.getCookie('c_p');
        var cookiesAnalysisThirdParties = cookieUtils.getCookie('c_t');
        var CookiesPersonalizationThirdParties = cookieUtils.getCookie('c_tp');
        var CookiesAdvertisingThirdParties = cookieUtils.getCookie('c_tpc');
        if (cookiesAnalysisOwn === 'true') {
            $('#analisis_propias input[type=checkbox]').attr('checked', true);
        } else {
            $('#analisis_propias input[type=checkbox]').attr('checked', false);
        }
        if (cookiesAnalysisThirdParties === 'true') {
            $('#analisis_terceros input[type=checkbox]').attr('checked', true);
        } else {
            $('#analisis_terceros input[type=checkbox]').attr('checked', false);
        }
        if (CookiesPersonalizationThirdParties === 'true') {
            $('#personalizacion_terceros input[type=checkbox]').attr('checked', true);
        } else {
            $('#personalizacion_terceros input[type=checkbox]').attr('checked', false);
        }
        if (CookiesAdvertisingThirdParties === 'true') {
            $('#publicidad_terceros input[type=checkbox]').attr('checked', true);
        } else {
            $('#publicidad_terceros input[type=checkbox]').attr('checked', false);
        }
    });

    $('button#guardar').click(function (e) {
        $.spinner().start();
        e.preventDefault();
        var consent = 'false';
        var url = $(this).data('url');
        var cookiesAnalysisOwn = $('input#p').is(':checked');
        var cookiesAnalysisThirdParties = $('input#t').is(':checked');
        var CookiesPersonalizationThirdParties = $('input#tp').is(':checked');
        var CookiesAdvertisingThirdParties = $('input#tpc').is(':checked');
        var ownCookies;
        var thirdPartyCookies;
        if (cookiesAnalysisOwn) {
            document.cookie = 'c_p=true;expires=' + setExpirationTime() + ';path=/';
            ownCookies = true;
        } else {
            document.cookie = 'c_p=false;expires=' + setExpirationTime() + ';path=/';
            ownCookies = false;
        }
        if (cookiesAnalysisThirdParties) {
            document.cookie = 'c_t=true;expires=' + setExpirationTime() + ';path=/';
        } else {
            document.cookie = 'c_t=false;expires=' + setExpirationTime() + ';path=/';
        }
        if (CookiesPersonalizationThirdParties) {
            document.cookie = 'c_tp=true;expires=' + setExpirationTime() + ';path=/';
        } else {
            document.cookie = 'c_tp=false;expires=' + setExpirationTime() + ';path=/';
        }
        if (CookiesAdvertisingThirdParties) {
            document.cookie = 'c_tpc=true;expires=' + setExpirationTime() + ';path=/';
        } else {
            document.cookie = 'c_tpc=false;expires=' + setExpirationTime() + ';path=/';
        }
        if (cookiesAnalysisOwn && cookiesAnalysisThirdParties && CookiesPersonalizationThirdParties && CookiesAdvertisingThirdParties) {
            consent = 'true';
        }
        if (cookiesAnalysisThirdParties && CookiesPersonalizationThirdParties && CookiesAdvertisingThirdParties) {
            thirdPartyCookies = 'true';
        } else {
            thirdPartyCookies = 'false';
        }
        var params = {
            consent: consent,
            ownCookies: ownCookies,
            thirdPartyCookies: thirdPartyCookies
        };
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            data: params,
            success: function () {
                $('#consent-tracking').remove();
                window.location.href = document.referrer;
                $.spinner().stop();
            },
            error: function () {
                $('#consent-tracking').remove();
                $.spinner().stop();
            }
        });
    });

    $('button#aceptar').click(function (e) {
        $.spinner().start();
        e.preventDefault();
        var url = $(this).data('url');
        var consent = 'true';
        var params = {
            consent: consent,
            ownCookies: true,
            thirdPartyCookies: true
        };
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            data: params,
            success: function () {
                $('#consent-tracking').remove();
                $('.toggle-box-cookies input[type=checkbox]').attr('checked', true);
                document.cookie = 'c_p=true;expires=' + setExpirationTime() + ';path=/';
                document.cookie = 'c_t=true;expires=' + setExpirationTime() + ';path=/';
                document.cookie = 'c_tp=true;expires=' + setExpirationTime() + ';path=/';
                document.cookie = 'c_tpc=true;expires=' + setExpirationTime() + ';path=/';
                window.location.href = document.referrer;
                $.spinner().stop();
            },
            error: function () {
                $('#consent-tracking').remove();
                $.spinner().stop();
            }
        });
    });

    $('button#rechazar').click(function (e) {
        $.spinner().start();
        e.preventDefault();
        var url = $(this).data('url');
        var consent = 'false';
        var params = {
            consent: consent,
            ownCookies: false,
            thirdPartyCookies: false
        };
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            data: params,
            success: function () {
                $('#consent-tracking').remove();
                $('.toggle-box-cookies input[type=checkbox]').attr('checked', false);
                document.cookie = 'c_p=false;expires=' + setExpirationTime() + ';path=/';
                document.cookie = 'c_t=false;expires=' + setExpirationTime() + ';path=/';
                document.cookie = 'c_tp=false;expires=' + setExpirationTime() + ';path=/';
                document.cookie = 'c_tpc=false;expires=' + setExpirationTime() + ';path=/';
                window.location.href = document.referrer;
                $.spinner().stop();
            },
            error: function () {
                $('#consent-tracking').remove();
                $.spinner().stop();
            }
        });
    });
};
